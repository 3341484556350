import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  editQuantityOrders,
  fetchAllUsers,
  clearAllUsers
} from '../../../actions/usersActions';
import { Form, Modal, ModalTitle, FormGroup } from '../../elements/elements';
import moment from 'moment';

const getWordEnd = (word, value, onlyWord) => {
  let answer = '';
  if (!onlyWord) answer = `${value} `;

  const string = value.toString();
  let numeral = parseInt(string[string.length - 1], 10);

  // Hardcode
  if (value === 11) {
    numeral = 5;
  }

  switch (word.toLowerCase()) {
    case 'продукт':
      if (numeral === 0) {
        return (answer += 'продуктов');
      } else if (numeral === 1) {
        return (answer += 'продукт');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'продукта');
      } else if (numeral >= 5) {
        return (answer += 'продуктов');
      }
      return (answer += word);

    case 'рубль':
      if (numeral === 0) {
        return (answer += 'рублей');
      } else if (numeral === 1) {
        return (answer += 'рубль');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'рубля');
      } else if (numeral >= 5) {
        return (answer += 'рублей');
      }
      return (answer += word);

    case 'балл':
      if (numeral === 0) {
        return (answer += 'баллов');
      } else if (numeral === 1) {
        return (answer += 'балл');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'балла');
      } else if (numeral >= 5) {
        return (answer += 'баллов');
      }
      return (answer += word);

    case 'день':
      if (numeral === 0) {
        return (answer += 'дней');
      } else if (numeral === 1) {
        return (answer += 'день');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'дня');
      } else if (numeral >= 5) {
        return (answer += 'дней');
      }
      return (answer += word);

    case 'купон':
      if (numeral === 0) {
        return (answer += 'купонов');
      } else if (numeral === 1) {
        return (answer += 'купон');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'купона');
      } else if (numeral >= 5) {
        return (answer += 'купонов');
      }
      return (answer += word);

    case 'заказ':
      if (numeral === 0) {
        return (answer += 'заказов');
      } else if (numeral === 1) {
        return (answer += 'заказ');
      } else if (numeral > 1 && numeral < 5) {
        return (answer += 'заказа');
      } else if (numeral >= 5) {
        return (answer += 'заказов');
      }
      return (answer += word);

    case 'голос':
      const strNumeral = numeral.toString();
      if (numeral !== 11 && strNumeral[strNumeral.length - 1] === '1') {
        return (answer += 'голоса');
      }
      return (answer += 'голосов');

    default:
      break;
  }

  return answer;
};

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: '',
      monthly: ''
    };
  }

  refreshPage = () => {
    this.props.clearUsers();
    this.props.clearAllUsers();
    this.props.fetchAllUsers(this.props.data.user, 'all');
    this.props.fetchUsers(this.props.coupons.user, 'all');
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  componentWillReceiveProps(newState) {
    if (!this.props.show && newState.show) {
      this.setState({
        total: newState.orderData.data.totalOrders,
        monthly: newState.orderData.data.monthlyOrders
      });
    }
  }

  onSubmit = () => {
    const id = this.props.orderData.data._id;
    if (id) {
      const { total, monthly } = this.state;

      const data = {
        total,
        monthly
      };

      this.props.editQuantityOrders(id, data, this.refresh);
    } else alert('Отсутствует id пользователя');
  };

  render() {
    const { total } = this.state;
    // const { levelInfo } = this.props.orderData.data;
    return (
      <Modal
        show={this.props.show}
        toggleModal={this.props.toggleModal}
      >
        <Form
          direction='column'
          onSubmit={this.onSubmit}
          value='Изменить кол-во заказов'
        >
          <ModalTitle title='Информация' />
          {this.props.show ? (
            <div>
              {this.props.orderData.data.bonusLevel >= 7 &&
              this.props.orderData.data.statusExpires ? (
                <>
                  <div>
                    Текущий статус: {this.props.orderData.data.levelInfo.name}
                  </div>
                  <div>
                    Для сохранения текущего статуса необходимо сделать:{' '}
                    {getWordEnd(
                      'заказ',
                      this.props.orderData.data.monthlyToKeepLevel
                    )}{' '}
                    до{' '}
                    {moment(this.props.orderData.data.statusExpires).format(
                      'DD-MM-YYYY'
                    )}
                  </div>
                  <div>
                    {`Количество заказов за последние ${
                      this.props.orderData.data.levelInfo.daysRequirement
                    } дней (${moment()
                      .add(
                        -1 *
                          this.props.orderData.data.levelInfo.daysRequirement,
                        'days'
                      )
                      .format('DD-MM-YYYY')} - ${moment().format(
                      'DD-MM-YYYY'
                    )}): 
                    ${
                      this.props.orderData.orders.orders.filter(order => {
                        return (
                          new Date(order.createdAt) >
                            new Date(
                              moment().add(
                                -1 *
                                  this.props.orderData.data.levelInfo
                                    .daysRequirement,
                                'days'
                              )
                            ) &&
                          order.sum > 900 &&
                          order.state === 'Закрыта'
                        );
                      }).length
                    }`}
                  </div>
                  <div>
                    Для перехода на новый уровень необходимо сделать:{' '}
                    {getWordEnd(
                      'заказ',
                      this.props.orderData.data.monthlyToNextLevel
                    )}
                    , сформированных за последние{' '}
                    {this.props.orderData.data.nextStatusDays} дней
                  </div>
                  <div>
                    Текущее кол-во заказов за период с{' '}
                    {moment()
                      .add(
                        -1 * this.props.orderData.data.nextStatusDays,
                        'days'
                      )
                      .format('DD-MM-YYYY')}{' '}
                    по {moment().format('DD-MM-YYYY')} -{' '}
                    {getWordEnd('заказ', this.props.orderData.data.currentNext)}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          <FormGroup
            id='total'
            maxlength='4'
            name='total'
            onChange={this.changeInput}
            placeholder='Всего заказов'
            required
            value={total}
          />
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderData: state.modal.modalContent
  };
}

export default connect(mapStateToProps, {
  fetchUsers,
  fetchAllUsers,
  clearUsers,
  clearAllUsers,
  editQuantityOrders
})(ModalComponent);
